<template>
  <table id="stats">
    <tbody>
      <template v-for="(castle, i) in top" :key="i">
        <tr>
          <td class="text-center castle_img_td" rowspan="6">
            <img
              width="150"
              class="castle_img"
              :src="getCastle(i + 1)"
              :alt="castle.name"
            />
          </td>
        </tr>
        <tr>
          <td>
            <b>Name:</b> <span translate="no">{{ castle.name }}</span>
          </td>
        </tr>
        <tr>
          <td>Tax: {{ castle.taxPercent }} %, {{ castle.siegeDate }}</td>
        </tr>
        <tr>
          <td>
            <b>Owner</b>: <span translate="no">{{ castle.clan_name }}</span>
          </td>
        </tr>
        <tr translate="no">
          <td class="castle_attackers">
            <b translate="yes">Attackers</b>:
            <template v-if="castle['att_def']">
              <template v-for="(attacker, i) in castle['att_def']">
                <template v-if="attacker.type === 'attackers'">
                  <template v-if="i < castle['att_def'].length - 1">
                    {{ attacker.clan_name }},
                  </template>
                  <template v-else>
                    {{ attacker.clan_name }}
                  </template>
                </template>
              </template>
            </template>
          </td>
        </tr>
        <tr translate="no">
          <td>
            <b translate="yes">Defenders</b>:
            <template v-if="castle['att_def']">
              <template v-for="(attacker, i) in castle['att_def']">
                <template v-if="attacker.type !== 'attackers'">
                  <template v-if="i < castle['att_def'].length - 1">
                    {{ attacker.clan_name }},
                  </template>
                  <template v-else>
                    {{ attacker.clan_name }}
                  </template>
                </template>
              </template>
            </template>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Castle",
  data() {
    return {
      dates: [],
    };
  },
  props: {
    top: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getCastle(castle_id) {
      return require(`/src/assets/images/castle/${castle_id}.jpg`);
    },
    getDate(timestamp) {
      let date = new Date(timestamp / 100);

      return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    },
  },
};
</script>

<style scoped>
.castle_img {
  min-width: 150px !important;
}

.castle_img_td {
  width: 160px !important;
}

.castle_attackers {
  white-space: break-spaces !important;
}
</style>
