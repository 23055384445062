<template>
  <div>
    <div class="field field_grp field_mb fit-cont">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Server</div>
      </div>
      <select
        name="stats_server"
        v-model="stats_default_server"
        class="tt-uppercase"
        translate="no"
      >
        <option
          v-for="(server, index) in this.servers"
          :key="index"
          :value="server.id"
        >
          {{ server.name }} x{{ server.rate }}
        </option>
      </select>
    </div>

    <div class="field field_grp field_mb fit-cont">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__rank_2.png" alt="" />
        </div>
        <div class="field__title">Rank</div>
      </div>
      <select
        name="stats_type"
        v-model="stats_type"
        class="tt-uppercase"
        translate="no"
      >
        <option value="level">LEVEL</option>
        <option value="pvp">PVP</option>
        <option value="pk">PK</option>
        <option value="clan">CLAN</option>
        <option value="castle">CASTLE</option>
        <option value="epic">EPIC ITEMS</option>
      </select>
    </div>

    <template
      v-if="
        stats_type === 'pk' || stats_type === 'pvp' || stats_type === 'level'
      "
    >
      <div class="field field_grp field_mb fit-cont">
        <div class="field__desc">
          <div class="field__ico">
            <img src="@/assets/images/icons/ico__user.png" alt="" />
          </div>
          <label for="search_char_id">
            <span class="field__title">Character</span>
          </label>
        </div>
        <input
          type="text"
          name="search_char"
          minlength="3"
          maxlength="16"
          v-model="char"
          id="search_char_id"
          @keyup.enter="search_character"
        />
        <template v-if="char">
          <div class="field__ico">
            <img
              class="search_btn"
              src="@/assets/images/icons/ico__close.png"
              alt="Search"
              @click="closeBtnClick"
            />
          </div>
        </template>
        <div class="field__ico">
          <img
            class="search_btn"
            :class="{ op_50: btn_disabled }"
            src="@/assets/images/icons/ico__acc_search.png"
            alt="Search"
            @click="search_character"
          />
        </div>
      </div>
    </template>

    <div class="response success fadein" ref="response" v-show="response">
      {{ response }}
    </div>
    <div class="response error fadein" ref="errors" v-show="errors.length">
      <template v-for="(error, idx) in errors" :key="idx">
        <template v-if="errors.length > 1">
          <li>{{ error }}</li>
        </template>
        <template v-else>
          {{ error }}
        </template>
      </template>
    </div>

    <div v-show="loading">
      <Loading />
    </div>

    <template v-if="stats_type === 'level'">
      <template v-if="level[stats_default_server]">
        <template v-if="!this.is_search_result">
          <Level :top="level[stats_default_server][stats_type]" />
        </template>
        <template v-if="this.is_search_result && this.players_found">
          <Level :top="this.players_found" />
        </template>
      </template>
      <template v-else>
        <Loading />
      </template>
    </template>

    <template v-if="stats_type === 'pvp'">
      <template v-if="pvp[stats_default_server]">
        <template v-if="!this.is_search_result">
          <PVP :top="pvp[stats_default_server][stats_type]" />
        </template>
        <template v-if="this.is_search_result && this.players_found">
          <PVP :top="this.players_found" />
        </template>
      </template>
      <template v-else>
        <Loading />
      </template>
    </template>

    <template v-if="stats_type === 'pk'">
      <template v-if="pk[stats_default_server]">
        <template v-if="!this.is_search_result">
          <PK :top="pk[stats_default_server][stats_type]" />
        </template>
        <template v-if="this.is_search_result && this.players_found">
          <PK :top="this.players_found" />
        </template>
      </template>
      <template v-else>
        <Loading />
      </template>
    </template>

    <template v-if="stats_type === 'clan'">
      <template v-if="clan[stats_default_server]">
        <Clan :top="clan[stats_default_server][stats_type]" />
      </template>
      <template v-else>
        <Loading />
      </template>
    </template>

    <template v-if="stats_type === 'castle'">
      <template v-if="castle[stats_default_server]">
        <Castle :top="castle[stats_default_server][stats_type]" />
      </template>
      <template v-else>
        <Loading />
      </template>
    </template>

    <template v-if="stats_type === 'epic'">
      <template v-if="epic[stats_default_server]">
        <EpicItem :top="epic[stats_default_server][stats_type]" />
      </template>
      <template v-else>
        <Loading />
      </template>
    </template>
  </div>
</template>

<script>
import PVP from "@/components/Modal/RankingTables/PVP";
import PK from "@/components/Modal/RankingTables/PK";
import Clan from "@/components/Modal/RankingTables/Clan";
import Castle from "@/components/Modal/RankingTables/Castle";
import EpicItem from "@/components/Modal/RankingTables/EpicItem";
import Level from "@/components/Modal/RankingTables/Level";
import Loading from "@/components/Loading";
import axios from "axios";

export default {
  name: "Statistics",
  components: { PVP, Loading, PK, Clan, Castle, EpicItem, Level },
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      btn_disabled: false,
      errors: [],
      response: null,
      char: null,
      is_search_result: false,
      players_found: [],
      stats_type: "level",
      search_timeout: 5000,
      last_query_char: null,
      last_query_sid: null,
      last_query_type: null,
      current_timestamp: new Date().getTime(),
    };
  },
  methods: {
    closeBtnClick() {
      this.char = null;
      this.is_search_result = false;
      this.errors = [];
      this.response = null;
      this.last_query_char = null;
      this.last_query_sid = null;
      this.last_query_type = null;
    },
    cleanState() {
      this.errors = [];
      this.response = null;
      this.btn_disabled = false;
    },
    async search_character() {
      const regex = /^([0-9A-Za-z]{3,16})$/gi;
      const matches = regex.exec(this.char);

      if (this.errors) {
        if (this.errors.length) {
          return;
        }
      }

      if (this.btn_disabled) {
        this.errors.push(
          "Search request limit exceeded, please try again later"
        );
      }

      if (
        this.last_query_char === this.char &&
        this.last_query_type === this.stats_type &&
        this.last_query_sid === this.stats_default_server
      ) {
        this.errors.push("Your search query matches the current result");
      }

      if (!this.char) {
        this.errors.push("Character name must not be empty");
      } else {
        if (this.char.length < 3) {
          this.errors.push("Character name minimum length is 3");
        }

        if (this.char.length > 16) {
          this.errors.push("Character maximum length is 16");
        }

        if (this.char.length >= 3 && this.char.length <= 16) {
          if (!matches) {
            this.errors.push("Illegal characters found");
          }
        }
      }

      this.btn_disabled = true;

      if (this.errors.length) {
        setTimeout(() => {
          this.$refs.errors.classList.add("fadeout");
          setTimeout(() => {
            this.$refs.errors.classList.remove("fadeout");
            this.errors = [];
            this.btn_disabled = false;
          }, 750);
        }, this.search_timeout);
        return;
      }

      this.last_query_char = this.char;
      this.last_query_sid = this.stats_default_server;
      this.last_query_type = this.stats_type;
      this.loading = true;

      await axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/api/v2/search_char/${this.char}/${this.stats_default_server}/${this.stats_type}`
        )
        .then((response) => {
          this.loading = false;
          this.is_search_result = true;
          this.players_found = response.data;
          this.response = this.found_characters;

          setTimeout(() => {
            if (!this.$refs.response.classList.contains("fadeout")) {
              this.$refs.response.classList.add("fadeout");
            }
            setTimeout(() => {
              if (this.$refs.response.classList.contains("fadeout")) {
                this.$refs.response.classList.remove("fadeout");
              }
              this.response = null;
              this.btn_disabled = false;
            }, 750);
          }, this.search_timeout);
        })
        .catch((error) => {
          this.errors.push(
            error.response.data.messages[
              Object.keys(error.response.data.messages)[0]
            ]
          );
          this.is_search_result = true;
          this.players_found = [];
          this.loading = false;

          setTimeout(() => {
            this.$refs.errors.classList.add("fadeout");
            setTimeout(() => {
              this.$refs.errors.classList.remove("fadeout");
              this.errors = [];
              this.btn_disabled = false;
            }, 750);
          }, this.search_timeout);
        });
    },
  },
  computed: {
    servers() {
      return this.$store.getters.get_servers;
    },
    get_default_server() {
      return this.$store.getters.get_default_server;
    },
    pvp() {
      return this.$store.getters.pvp;
    },
    pk() {
      return this.$store.getters.pk;
    },
    clan() {
      return this.$store.getters.clan;
    },
    castle() {
      return this.$store.getters.castle;
    },
    epic() {
      return this.$store.getters.epic;
    },
    level() {
      return this.$store.getters.level;
    },
    found_characters() {
      return `Found ${this.players_found.length} character${
        this.players_found.length > 1 ? "s" : ""
      }`;
    },
    stats_default_server: {
      get() {
        return this.$store.getters.get_stats_default_server;
      },
      set(value) {
        this.$store.dispatch("update_stats_default_server", value);
        this.$router.push({ path: "/stats/" + value });
        this.$store.dispatch("update_current_server", value);
      },
    },
    top_level() {
      return this.$store.getters.level;
    },
    top_pvp() {
      return this.$store.getters.pvp;
    },
    top_pk() {
      return this.$store.getters.pk;
    },
    top_clan() {
      return this.$store.getters.clan;
    },
    top_castle() {
      return this.$store.getters.castle;
    },
    top_epic() {
      return this.$store.getters.epic;
    },
    top_level_expired() {
      return this.$store.getters.level_expired;
    },
    top_pvp_expired() {
      return this.$store.getters.pvp_expired;
    },
    top_pk_expired() {
      return this.$store.getters.pk_expired;
    },
    top_clan_expired() {
      return this.$store.getters.clan_expired;
    },
    top_castle_expired() {
      return this.$store.getters.castle_expired;
    },
    top_epic_expired() {
      return this.$store.getters.epic_expired;
    },
  },
  async beforeCreate() {
    await this.$store.dispatch(
      "init_stats_default_server",
      this.$route.params.id ||
        this.$store.getters.get_stats_default_server ||
        this.$store.getters.get_default_server
    );
    if (!this.$props.id) {
      await this.$router.push({
        name: "Statistics",
        params: {
          id:
            this.$store.getters.get_stats_default_server ||
            this.$store.getters.get_default_server,
        },
      });
    }
  },
  async mounted() {
    this.$store.dispatch("init_top_level");
    this.$store.dispatch("init_top_pvp");
    this.$store.dispatch("init_top_pk");
    this.$store.dispatch("init_top_clan");
    this.$store.dispatch("init_top_castle");
    this.$store.dispatch("init_top_epic");

    if (!this.top_level) {
      await this.$store.dispatch("fetch_top_level");
    } else {
      if (this.top_level_expired < this.current_timestamp)
        await this.$store.dispatch("fetch_top_level");
    }

    if (!this.top_pvp) {
      await this.$store.dispatch("fetch_top_pvp");
    } else {
      if (this.top_pvp_expired < this.current_timestamp)
        await this.$store.dispatch("fetch_top_pvp");
    }

    if (!this.top_pk) {
      await this.$store.dispatch("fetch_top_pk");
    } else {
      if (this.top_pk_expired < this.current_timestamp)
        await this.$store.dispatch("fetch_top_pk");
    }

    if (!this.top_clan) {
      await this.$store.dispatch("fetch_top_clan");
    } else {
      if (this.top_clan_expired < this.current_timestamp)
        await this.$store.dispatch("fetch_top_clan");
    }

    if (!this.top_castle) {
      await this.$store.dispatch("fetch_top_castle");
    } else {
      if (this.top_castle_expired < this.current_timestamp)
        await this.$store.dispatch("fetch_top_castle");
    }

    if (!this.top_epic) {
      await this.$store.dispatch("fetch_top_epic");
    } else {
      if (this.top_epic_expired < this.current_timestamp)
        await this.$store.dispatch("fetch_top_epic");
    }
  },
};
</script>

<style scoped>
/*table {*/
/*  font-family: Tahoma, Arial, Verdana, sans-serif;*/
/*  font-size: 12px;*/
/*}*/
</style>

<style>
hr {
  margin: 0;
  padding: 0;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #444; /* old IE */
  background-color: #444; /* Modern Browsers */
  opacity: 0.5;
}

.op_50 {
  opacity: 0.5;
}

.search_btn {
  cursor: pointer;
  max-width: unset;
}
</style>
