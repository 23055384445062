<template>
  <div v-for="(item, i) in items" :key="item.id">
    <div
      class="flex flex3"
      @click="clickedItem(i)"
      :class="{ flex3_active: active_item === i }"
    >
      <div class="flex-items">
        <img
          :src="getEpicImage(item.id)"
          class="img_correction"
          :alt="item.name"
        />
      </div>
      <div class="flex-items" translate="no">
        {{ item.name }} (<b>{{ top[i].length }}</b
        >)
      </div>
      <div class="flex-items">
        <img
          :src="getEpicGrade(item.grade)"
          class="grade_correction"
          :alt="`Grade: ${item.grade}`"
        />
      </div>
    </div>
    <div>
      <EpicItemTable v-show="active_item === i" :top="top[i]" />
    </div>
  </div>
</template>

<script>
import EpicItemTable from "@/components/Modal/RankingTables/EpicItemTable";

export default {
  name: "EpicItem",
  components: { EpicItemTable },
  props: {
    top: Object,
  },
  data() {
    return {
      active_item: null,
      items: [
        { id: 6662, name: "Ring of Core", grade: "a" },
        { id: 6661, name: "Earring of Orfen", grade: "a" },
        { id: 6660, name: "Ring of Queen Ant", grade: "b" },
        { id: 6659, name: "Earring of Zaken", grade: "s" },
        { id: 6658, name: "Ring of Baium", grade: "s" },
        { id: 6657, name: "Necklace of Valakas", grade: "s" },
        { id: 6656, name: "Earring of Antharas", grade: "s" },
        { id: 8191, name: "Necklace of Frintezza", grade: "a" },
      ],
    };
  },
  methods: {
    getEpicImage(item_id) {
      return require("@/assets/images/icons/items/" + item_id + ".gif");
    },
    getEpicGrade(grade) {
      return require("@/assets/images/icons/grade/grade_" + grade + ".gif");
    },
    clickedItem(clicked_id) {
      if (clicked_id === this.active_item) {
        this.active_item = null;
      } else {
        this.active_item = clicked_id;
      }
    },
  },
};
</script>

<style scoped>
thead tr th {
  border: 1px solid #111;
  font-weight: bold;
  font-size: 0.9rem;
  border-collapse: collapse;
}
tbody tr td {
  font-size: 0.9rem;
}

.flex {
  display: flex;
  text-align: center;
  background-color: #333;
  border: 1px solid #111;
  margin-top: 2px;
}

.flex3 {
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.flex3:hover {
  background: #444;
}

.flex3_active {
  background: #1a1a1a;
}

.flex-items {
  text-align: center;
  font-size: 1rem;
  vertical-align: center;
  max-height: 36px;
}

.img_correction {
  border: 1px solid #222;
  margin: 1px;
}

.grade_correction {
  align-items: center;
  padding-right: 15px;
}
</style>
