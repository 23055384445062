<template>
  <table id="stats">
    <thead>
      <tr>
        <th>#</th>
        <th style="max-width: 50px !important">Clan name</th>
        <th>Level</th>
        <th>Score</th>
        <th>Members</th>
        <th>PvP</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(clan, i) in top" :key="i">
        <tr>
          <td class="text-center">{{ clan.rank }}</td>
          <td class="bold" translate="no">{{ clan.name }}</td>
          <td class="text-center">{{ clan.clan_level }}</td>
          <td class="text-center bold">
            {{ comma_seperated(clan.reputation_score) }}
          </td>
          <td class="text-center">{{ clan.countMembers }}</td>
          <td class="text-center">{{ comma_seperated(clan.countPvpKills) }}</td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Clan",
  props: {
    top: {
      type: Object,
      required: true,
    },
  },
  methods: {
    comma_seperated(num) {
      return Number(num).toLocaleString("en-US");
    },
  },
};
</script>

<style scoped></style>
